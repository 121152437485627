export const ENABLE_MESSAGING = 'ENABLE_MESSAGING';
const DISABLE_MESSAGING = 'DISABLE_MESSAGING';
const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
const UPDATE_PAGE = 'UPDATE_PAGE';
const UPDATE_SEED_DATA = 'UPDATE_SEED_DATA';
const RESET_SEED_DATA = 'RESET_SEED_DATA';
const GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE';
const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
const RESET_NAVIGATION_HISTORY = 'RESET_NAVIGATION_HISTORY';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
const LOGGED_IN = 'LOGGED_IN';
const LOGGED_OUT = 'LOGGED_OUT';
const LOGGING_IN = 'LOGGING_IN';
const LOGGING_OUT = 'LOGGING_OUT';

export type UserStatus = 'LOGGED_IN' | 'LOGGED_OUT' | 'LOGGING_IN' | 'LOGGING_OUT';

export const UserStatuses = {
    LOGGED_IN,
    LOGGED_OUT,
    LOGGING_IN,
    LOGGING_OUT,
};

// TYPES
export type Options = {
    startPage: string;
    startPageAnchorParam: string | null;
    // Selected businessId option
    selectedBusinessId: string | null;
    // TODO: Remove because deprecated
    displayUserParams: boolean;
    // TODO: Remove because deprecated
    // Intercom display option
    displayIntercom: boolean;
    // App display options
    displaySideBar: boolean;
    displayAddButton: boolean;
    displayBusinessListSearch: boolean;
    displayPresenceManagementDownload: boolean;
    displayBusinessModalFilters: boolean;
    displayPartnerConnexionBanner: boolean;
    displayEditBusinessSelector: boolean;
    displayVerificationRequiredButton: boolean;
    businessFoundBannerText: string;
    style: {
        fontFamilyName: string | null;
    };
};

export type PageState = {
    cursor: number;
    userStatus: UserStatus;
    navigationHistory: Array<string>;
    messagingEnabled: boolean;
    options: Options;
    seedData: Record<string, any>;
};

type NavigateToAction = {
    type: 'NAVIGATE_TO';
    url: string;
};

type UpdatePageAction = {
    type: 'UPDATE_PAGE';
    url: string;
};

type GoToPreviousPageAction = {
    type: 'GO_TO_PREVIOUS_PAGE';
};

type GoToNextPageAction = {
    type: 'GO_TO_NEXT_PAGE';
};

type PageLoadingAction = {
    type: 'PAGE_LOADING';
};

type PageLoadedAction = {
    type: 'PAGE_LOADED';
};

type PageErrorAction = {
    type: 'PAGE_ERROR';
    errorCode: number;
};

type UpdateUserStatus = {
    type: 'UPDATE_USER_STATUS';
    status: UserStatus;
};

type EnableMessagingAction = {
    type: 'ENABLE_MESSAGING';
};

type DisableMessagingAction = {
    type: 'DISABLE_MESSAGING';
};

type LogoutUserAction = {
    type: 'LOGOUT_USER';
    logoutUrl: string;
};

type LoginUserAction = {
    type: 'LOGIN_USER';
    loginUrl: string;
};

type UpdateOptionsAction = {
    type: 'UPDATE_OPTIONS';
    options: Options;
};

type UpdateSeedDataAction = {
    type: 'UPDATE_SEED_DATA';
    seedData: Record<string, any>;
};

type ResetSeedDataAction = {
    type: 'RESET_SEED_DATA';
};

type ResetNavigationHistoryAction = {
    type: 'RESET_NAVIGATION_HISTORY';
};

type PageAction =
    | NavigateToAction
    | GoToPreviousPageAction
    | GoToNextPageAction
    | PageLoadingAction
    | PageLoadedAction
    | PageErrorAction
    | UpdateUserStatus
    | EnableMessagingAction
    | DisableMessagingAction
    | LogoutUserAction
    | LoginUserAction
    | UpdateSeedDataAction
    | ResetSeedDataAction
    | UpdateOptionsAction
    | UpdatePageAction
    | ResetNavigationHistoryAction;

export const updatePage = (url: string): UpdatePageAction => ({
    url,
    type: UPDATE_PAGE,
});

export const updateUserStatus = (status: UserStatus): UpdateUserStatus => ({
    status,
    type: UPDATE_USER_STATUS,
});

export const goToPreviousPage = (): GoToPreviousPageAction => ({
    type: GO_TO_PREVIOUS_PAGE,
});

export const goToNextPage = (): GoToNextPageAction => ({
    type: GO_TO_NEXT_PAGE,
});

export const resetNavigationHistory = (): ResetNavigationHistoryAction => ({
    type: RESET_NAVIGATION_HISTORY,
});

export const messagingIsEnabled = (): EnableMessagingAction => ({
    type: ENABLE_MESSAGING,
});

export const messagingIsDisabled = (): DisableMessagingAction => ({
    type: DISABLE_MESSAGING,
});

export const updateOptions = (options: Options): UpdateOptionsAction => ({
    type: UPDATE_OPTIONS,
    options,
});

export const updateSeedData = (seedData: Record<string, any>): UpdateSeedDataAction => ({
    type: UPDATE_SEED_DATA,
    seedData,
});

export const resetSeedData = (): ResetSeedDataAction => ({
    type: RESET_SEED_DATA,
});

const initialState = {
    cursor: -1,
    navigationHistory: [],
    userStatus: LOGGED_OUT as UserStatus,
    messagingEnabled: false,
    options: {
        startPage: 'businesses',
        startPageAnchorParam: null,
        // Selected businessId option
        selectedBusinessId: null,
        // TODO: Remove because deprecated
        displayUserParams: true,
        // TODO: Remove because deprecated
        // Intercom display option
        displayIntercom: true,
        // App display options
        displayAddButton: true,
        displaySideBar: false,
        displayBusinessListSearch: true,
        displayPartnerConnexionBanner: true,
        displayPresenceManagementDownload: true,
        displayEditBusinessSelector: true,
        displayBusinessModalFilters: true,
        displayVerificationRequiredButton: false,
        businessFoundBannerText: '',
        style: {
            fontFamilyName: null,
        },
    },
    seedData: {},
};

const page = (state: PageState = initialState, action: PageAction): PageState => {
    switch (action.type) {
        case UPDATE_PAGE:
            return {
                ...state,
                navigationHistory: [
                    ...state.navigationHistory.slice(0, state.cursor + 1),
                    action.url,
                ],
                cursor: state.cursor + 1,
            };

        case ENABLE_MESSAGING:
            return { ...state, messagingEnabled: true };

        case DISABLE_MESSAGING:
            return { ...state, messagingEnabled: false };

        case GO_TO_PREVIOUS_PAGE:
            if (state.navigationHistory.length > 0 && state.cursor > 0) {
                return { ...state, cursor: state.cursor - 1 };
            }

            console.warn('🚫 Cannot go back');

            return state;

        case GO_TO_NEXT_PAGE:
            if (
                state.navigationHistory.length > 0 &&
                state.cursor < state.navigationHistory.length - 1
            ) {
                return { ...state, cursor: state.cursor + 1 };
            }

            console.warn('🚫 Cannot go forward');

            return state;

        case UPDATE_OPTIONS:
            return {
                ...state,
                options: { ...state.options, ...action.options },
            };

        case UPDATE_SEED_DATA:
            return { ...state, seedData: { ...action.seedData } };

        case RESET_SEED_DATA:
            return { ...state, seedData: {} };

        case RESET_NAVIGATION_HISTORY:
            return { ...state, navigationHistory: [], cursor: -1 };

        case UPDATE_USER_STATUS:
            return { ...state, userStatus: action.status };

        default:
            return state;
    }
};

export default page;
