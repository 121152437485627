import { matchPath } from 'react-router-dom';
import URLSearchParams from 'url-search-params';

import { getPKCEParams } from 'app/common/services/sso/getParams';

// TODO: Should properly use react-router-dom instead
/** @deprecated */
export const testPath = (pathTemplate: string, path: string, exact = false): boolean =>
    !!matchPath({ path: pathTemplate, end: exact }, path);

// TODO: Should properly use the react-router-dom instead
/** @deprecated */
export const extractPathParam = (
    pathname: string,
    pathTemplate: string,
    pathParam: string,
): string => {
    const match = matchPath(
        {
            path: pathTemplate,
        },
        decodeURIComponent(pathname),
    );
    return match?.params?.[pathParam] || '';
};

// TODO: Should properly use the router instead
/** @deprecated */
export const extractSearchParam = (paramName: string): string => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(paramName) || '';
};

export const extractOidcParam = () => {
    // Handle only PKCE now
    const ssoAlias = extractSearchParam('sso_alias');

    if (!ssoAlias) {
        return null;
    }

    return getPKCEParams();
};

// BUSINESS MODAL SEARCH PARAMS
export const BUSINESSES_SELECT_ALL = 'select-all';
export const INCLUDED_BUSINESSES = 'included';
export const EXCLUDED_BUSINESSES = 'excluded';
export const BUSINESSES_QUERIES = 'queries';
export const BUSINESSES_CITIES = 'cities';
export const BUSINESSES_GROUPS = 'groups';
export const BUSINESSES_ORGS = 'orgs';

// Constant used by iframe sdk
// in the sdk there is an option 'businessId', if the option is set, the
// sdk will append a get param `?${BUSINESS_ID_PARAM}=${businessId}` each
// time we change the page. It must be equal to INCLUDED_BUSINESSES so
// that the sync with Redux happen. There's still a manual sync in the
// review booster. It was developed first manually without global sync thus
// the extra variable, but I let it as is to track easily iframe related
// parts.
export const BUSINESS_ID_PARAM = INCLUDED_BUSINESSES;

// BUSINESS LIST SEARCH PARAMS
export const BUSINESS_LIST_STATUS = 'status';
export const BUSINESS_LIST_PRODUCTS = 'products';

// POSTS SEARCH PARAMS
export const POSTS_DATE_FROM = 'date-from';
export const POSTS_DATE_TO = 'date-to';
export const POSTS_TOPICS = 'topics';
export const POST_PLATFORMS = 'platforms';

// REVIEWS SEARCH PARAMS
export const REVIEWS_DATE_FROM = 'date-from';
export const REVIEWS_DATE_TO = 'date-to';
export const REVIEWS_PLATFORMS = 'platforms';
export const REVIEWS_RATING = 'rating';
export const REVIEWS_COMMENTS = 'comments';
export const REVIEWS_KEYWORDS = 'keywords';
export const REVIEWS_TAG = 'tag';

// REVIEW ANALYTICS SEARCH PARAMS
export const REVIEW_ANALYTICS_DATE_FROM = 'date-from';
export const REVIEW_ANALYTICS_DATE_TO = 'date-to';
export const REVIEW_ANALYTICS_KEYWORDS = 'keywords';
export const REVIEW_ANALYTICS_TAGS = 'tag';

// REPLY TEMPLATES
export const REPLY_TEMPLATES_LANGUAGES = 'languages';
