export const LoginQueryKeys = {
    API_KEY: 'api_key',
    CONNECTION_TOKEN: 'connection_token',
    NEXT_PAGE: 'next_page',

    // SSO
    SSO_ALIAS: 'sso_alias',
    SSO_CODE: 'code',
    SSO_STATE: 'state',
};
